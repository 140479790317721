<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <textarea
        v-model="disabilityDetails"
        v-on:input="save"
        class="input-text input-standard-white fadeIn-2"
        rows="8"
        cols="5"
        placeholder="Here you may provided additional details"
      ></textarea>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/distribution/disability_option'"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'

export default {
  name: 'DistributionDisabledOption',
  components: {
    NavQuestion,
    NavQuestionButtons
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('saveDetails')
    next()
  },
  mounted() {
    this.disabilityDetails = this.$store.getters.details.distribution_disabled_details
  },
  computed: {
    partner() {
      return this.$store.getters.partner
    },
    number() {
      var number = 1
      if (this.partner) number += 1
      if (this.$store.getters.peopleDistributionPrimary)
        number += this.$store.getters.peopleDistributionPrimary.length
      if (this.$store.getters.groupsDistributionPrimary)
        number += this.$store.getters.groupsDistributionPrimary.length
      if (this.details.distribution_all_die_backup_table) number += 1
      if (this.details.distribution_disabled) number += 1
      if (
        !this.$store.getters.groupsDistributionPrimary.length &&
        this.$store.getters.peopleDistributionPrimary.length === 1 &&
        this.$store.getters.peopleDistributionPrimary[0]
          .distribution_backup_children_no
      )
        number -= 1
      return number + 3
    },
    isValidated() {
      if (this.disabilityDetails) return true
      return false
    },
    forwardTo() {
      return '/distribution/check_and_confirm'
    },
    details() {
      return this.$store.getters.details
    }
  },
  data() {
    return {
      heading:
        'Which of your beneficiaries are disabled and/or vulnerable and in what way?',
      subHeading:
        'Please briefly explain which of your beneficiaries are vulnerable and/or disabled and add a few details explaining their situation',
      disabilityDetails: ''
    }
  },
  methods: {
    save() {
      this.$store.commit('details', {
        distribution_disabled_details: this.disabilityDetails
      })
    }
  }
}
</script>

<style></style>
